
    export default {
        data() {
            return {
                width : null
            }
        },
        props : ['blok'],
        mounted() {
            this.width = this.$el.clientWidth;
        },
    }
